import { Timestamp } from "@bufbuild/protobuf";
import { createFileRoute } from "@tanstack/react-router";
import { startOfDay, subDays, subHours } from "date-fns";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import {
  type TransactionGroup,
  Transaction_Type,
  User_Gender,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import ArrowDownward from "~icons/material-symbols/arrow-downward";
import RestartAlt from "~icons/material-symbols/restart-alt";
import { css } from "../../../styled-system/css";
import { DateTimeRangePicker } from "../../components/date-time-range-picker";
import Id from "../../components/id";
import { formatNumber } from "../../libs/utils";

type RowProps = {
  group: TransactionGroup;
  onRefund: (transactionGroupId: string) => void;
};

const STORE_ID_KEY = "company-transactions-store-id";

export const Route = createFileRoute("/company/transactions")({
  loader: async ({ context: { client, stores } }) => {
    const defaultPagination = {
      offset: Timestamp.fromDate(new Date()),
      limit: Timestamp.fromDate(startOfDay(subDays(new Date(), 7))),
    };

    if (stores.length > 1) {
      defaultPagination.limit = Timestamp.fromDate(
        startOfDay(startOfDay(subDays(new Date(), 3))),
      );
    }

    if (stores.length > 5) {
      defaultPagination.limit = Timestamp.fromDate(
        startOfDay(startOfDay(subDays(new Date(), 1))),
      );
    }

    if (stores.length > 50) {
      defaultPagination.limit = Timestamp.fromDate(
        startOfDay(subHours(new Date(), 1)),
      );
    }

    const storedStoreId = localStorage.getItem(STORE_ID_KEY);
    const storeId =
      (storedStoreId ? stores.find((s) => s.id === storedStoreId)?.id : null) ??
      null;

    return { client, defaultPagination, stores, defaultStoreId: storeId };
  },
  component: RouteComponent,
});

function getTransactionType(group: TransactionGroup): Transaction_Type {
  for (const detail of group.transactionDetails) {
    if (
      !(
        detail.transaction?.type === Transaction_Type.REFUND ||
        detail.transaction?.type === Transaction_Type.PAYMENT ||
        detail.transaction?.type === Transaction_Type.REDEEM
      )
    ) {
      throw new Error("Invalid transaction type");
    }
  }

  const refund = group.transactionDetails.find(
    (d) => d.transaction?.type === Transaction_Type.REFUND,
  );
  if (refund) {
    return Transaction_Type.REFUND;
  }

  const redemption = group.transactionDetails.find(
    (d) => d.transaction?.type === Transaction_Type.REDEEM,
  );
  if (redemption) {
    return Transaction_Type.REDEEM;
  }

  return Transaction_Type.PAYMENT;
}

function Row({ group, onRefund }: RowProps) {
  const transactionType = getTransactionType(group);
  const paymentDetail = group.transactionDetails.find(
    (d) => d.transaction?.type === Transaction_Type.PAYMENT,
  );
  const paymentDetails = group.transactionDetails.filter(
    (d) => d.transaction?.type === Transaction_Type.PAYMENT,
  );
  const amount = paymentDetails.reduce(
    (acc, d) => acc + Number(d.transaction?.amount ?? 0),
    0,
  );
  const displayAmount = -amount;

  if (!paymentDetail) {
    // 支払いに関係しない取引は表示しない。存在もしないはず。
    return null;
  }

  return (
    <tr
      key={paymentDetail.transaction?.groupId}
      className={css({
        borderBottom: "1px solid",
        borderColor: "border.secondary",
        _last: {
          borderBottom: "none",
        },
      })}
    >
      <td
        className={css({
          py: "12px",
          px: "16px",
        })}
      >
        <span
          className={css({
            fontSize: "12px",
            bg: "background.background",
            borderRadius: "4px",
            px: "4px",
            py: "2px",
          })}
        >
          {group.id}
        </span>
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
        })}
      >
        {paymentDetail.transaction?.createdAt?.toDate().toLocaleString()}
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        })}
      >
        {paymentDetail.store?.name}
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
        })}
      >
        <Id id={paymentDetail.transaction?.userId ?? ""} />
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
        })}
      >
        {genderText(paymentDetail.user?.gender ?? User_Gender.UNSPECIFIED)}
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
        })}
      >
        {Number(paymentDetail.user?.ageGroup)}代
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
          textAlign: "right",
        })}
      >
        {transactionType === Transaction_Type.REFUND
          ? `(${formatNumber(displayAmount)})pt`
          : `${formatNumber(displayAmount)}pt`}
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        })}
      >
        {paymentDetail.transaction?.qrId}
      </td>
      <td
        className={css({
          py: "12px",
          px: "16px",
          textAlign: "center",
        })}
      >
        <button
          type="button"
          onClick={() => onRefund(group.id)}
          disabled={transactionType !== Transaction_Type.PAYMENT}
          className={css({
            color: "surface.accentPrimary",
            border: "1px solid",
            borderColor: "surface.accentPrimary",
            borderRadius: "4px",
            px: "12px",
            py: "4px",
            fontSize: "14px",
            cursor: "pointer",
            whiteSpace: "nowrap",
            minWidth: "100px",
            _disabled: {
              bg: "background.background",
              color: "text.secondary",
              cursor: "not-allowed",
              border: "none",
            },
          })}
        >
          {transactionTypeActionText(transactionType)}
        </button>
      </td>
    </tr>
  );
}

function genderText(gender: User_Gender) {
  switch (gender) {
    case User_Gender.MALE:
      return "男性";
    case User_Gender.FEMALE:
      return "女性";
    case User_Gender.UNSPECIFIED:
      return "不明";
    default:
      return "未定義";
  }
}

function transactionTypeActionText(type: Transaction_Type) {
  switch (type) {
    case Transaction_Type.PAYMENT:
      return "返金";
    case Transaction_Type.REDEEM:
      return "締め済み";
    case Transaction_Type.REFUND:
      return "返金済み";
  }
}

function RouteComponent() {
  const { client, defaultPagination, stores, defaultStoreId } =
    Route.useLoaderData();
  const [transactions, setTransactions] = useState<TransactionGroup[]>([]);
  const [offset, setOffset] = useState<Date>(defaultPagination.offset.toDate());
  const [limit, setLimit] = useState<Date>(defaultPagination.limit.toDate());
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [storeId, setStoreId] = useState<string | null>(defaultStoreId);
  const [isLoading, setIsLoading] = useState(false);

  const loadTransactions = useCallback(() => {
    setIsLoading(true);
    client
      .getTransactions({
        storeId: storeId || undefined,
        pagination: {
          limit: Timestamp.fromDate(limit),
          offset: Timestamp.fromDate(offset),
        },
      })
      .then((d) => {
        setTransactions(d.transactionGroups);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [client, limit, offset, storeId]);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  const transactionsWithoutRefund = useMemo(() => {
    return transactions.filter((group) => {
      const refund = group.transactionDetails.find(
        (d) => d.transaction?.type === Transaction_Type.REFUND,
      );
      return !refund;
    });
  }, [transactions]);

  const transactionCount = useMemo(() => {
    return transactions.length;
  }, [transactions]);

  const salesAmount = useMemo(() => {
    let amount = 0;

    for (const group of transactions) {
      for (const detail of group.transactionDetails) {
        if (
          detail.transaction?.type === Transaction_Type.PAYMENT ||
          detail.transaction?.type === Transaction_Type.REFUND
        ) {
          amount += Number(detail.transaction?.amount ?? 0);
        }
      }
    }

    if (amount < 0) {
      return -amount;
    }

    return amount;
  }, [transactions]);

  const handleDateSubmit = useCallback(
    (range: { limit: Date; offset: Date }) => {
      setIsOpenDatePicker(false);
      if (!range.limit || !range.offset) return;
      setOffset(range.offset);
      setLimit(range.limit);
    },
    [],
  );

  const handleClickRefund = useCallback(
    async (transactionGroupId: string) => {
      if (!window.confirm("返金を実行しますか？")) {
        return;
      }

      try {
        await client.refund({ transactionGroupId, reason: "返金" });
        toast.success("取引の返金に成功しました");
        loadTransactions();
      } catch (e) {
        toast.error("取引の返金に失敗しました");
      }
    },
    [client, loadTransactions],
  );

  const formatDateTime = useCallback((date: Date) => {
    return format(date, "yyyy/MM/dd HH:mm", { locale: ja });
  }, []);

  const handleDownloadCSV = useCallback(() => {
    const rows = transactionsWithoutRefund.map((group) => {
      const detail = group.transactionDetails.find(
        (d) => d.transaction?.type === Transaction_Type.PAYMENT,
      );

      let amount = 0;
      for (const detail of group.transactionDetails) {
        if (detail.transaction?.type === Transaction_Type.PAYMENT) {
          amount += Number(detail.transaction?.amount ?? 0);
        }
      }

      const isRedeemed = group.transactionDetails.some(
        (d) => d.transaction?.type === Transaction_Type.REDEEM,
      );

      const displayAmount = -amount;

      if (!detail) {
        throw new Error("Payment detail not found");
      }

      return {
        日時: formatDateTime(
          detail.transaction?.createdAt?.toDate() ?? new Date(), // デフォルト値を設定しているが、undefinedのケースはない
        ),
        店舗名: detail.store?.name,
        支払ユーザーID: detail.transaction?.userId,
        性別: genderText(detail.user?.gender ?? User_Gender.UNSPECIFIED),
        年齢: `${Number(detail.user?.ageGroup)}代`,
        ポイント: displayAmount,
        QRコードタグ: detail.transaction?.qrId,
        売上状態: isRedeemed ? "締め済み" : "売上確定",
      };
    });

    const csvConfig = mkConfig({
      filename: `取引履歴_${format(limit, "yyyy-MM-dd-HH-mm")}_${format(offset, "yyyy-MM-dd-HH-mm")}`,
      fieldSeparator: ",",
      useBom: true,
      useKeysAsHeaders: true,
    });

    const csv = generateCsv(csvConfig)(rows);
    download(csvConfig)(csv);
  }, [transactionsWithoutRefund, limit, offset, formatDateTime]);

  return (
    <div
      className={css({
        py: "24px",
        pb: "100px",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        px: { base: "20px", md: "40px" },
        maxWidth: { base: "100vw", md: "100%" },
      })}
    >
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        取引履歴
      </h1>
      <div
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "6px",
          mb: "12px",
          width: "280px",
        })}
      >
        <div
          className={css({
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          <div>ポイント利用件数</div>
          <div>{transactionCount}件</div>
        </div>
        <div
          className={css({
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          <div>ポイント売上合計</div>
          <div>{formatNumber(salesAmount)}pt</div>
        </div>
      </div>
      <div
        className={css({
          overflowX: {
            base: "auto",
            md: "hidden",
          },
          overflowY: "hidden",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        })}
      >
        <div
          className={css({
            mt: "18px",
          })}
        >
          <div
            className={css({
              position: "relative",
              display: "flex",
              flexDirection: { base: "column", md: "row" },
              gap: { base: "12px", md: "16px" },
            })}
          >
            <div
              className={css({
                display: "flex",
                gap: "16px",
                width: { base: "100%", md: "auto" },
              })}
            >
              <button
                type="button"
                className={css({
                  bg: "surface.accentPrimary",
                  color: "text.inverse",
                  border: "none",
                  borderRadius: "6px",
                  px: "12px",
                  py: "4px",
                  fontSize: "14px",
                  cursor: "pointer",
                  width: "100%",
                })}
                onClick={() => setIsOpenDatePicker(true)}
              >
                {`日時：${formatDateTime(limit)} ~ ${formatDateTime(offset)}`}
              </button>
            </div>

            <div
              className={css({
                display: "flex",
                gap: "16px",
                width: { base: "100%", md: "auto" },
              })}
            >
              <select
                value={storeId ?? ""}
                onChange={(e) => {
                  const selectedStore = stores.find(
                    (s) => s.id === e.target.value,
                  );
                  setStoreId(selectedStore ? selectedStore.id : null);

                  if (selectedStore) {
                    localStorage.setItem(STORE_ID_KEY, selectedStore.id);
                  } else {
                    localStorage.removeItem(STORE_ID_KEY);
                  }
                }}
                className={css({
                  border: "1px solid",
                  borderColor: "border.secondary",
                  borderRadius: "8px",
                  px: "12px",
                  cursor: "pointer",
                  bg: "white",
                  width: "100%",
                })}
              >
                <option value="">すべての店舗</option>
                {stores.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name.length > 12 ? `${s.name.slice(0, 12)}...` : s.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              className={css({
                display: { base: "none", md: "flex" },
                gap: "16px",
                width: { base: "100%", md: "auto" },
                marginLeft: { base: "0", md: "auto" },
              })}
            >
              <button
                type="button"
                className={css({
                  border: "1px solid",
                  borderColor: "surface.accentPrimary",
                  color: "surface.accentPrimary",
                  borderRadius: "6px",
                  pl: "10px",
                  pr: "12px",
                  py: "4px",
                  fontSize: "14px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  flex: { base: "1", md: "none" },
                })}
                onClick={() => {
                  location.reload();
                }}
              >
                <RestartAlt />
                <span>リロード</span>
              </button>

              <button
                type="button"
                className={css({
                  bg: "surface.accentPrimary",
                  color: "text.inverse",
                  border: "none",
                  borderRadius: "6px",
                  px: "12px",
                  py: "4px",
                  fontSize: "14px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  _disabled: {
                    bg: "background.background",
                    color: "text.secondary",
                    cursor: "not-allowed",
                  },
                  flex: { base: "1", md: "none" },
                })}
                disabled={transactionCount === 0}
                onClick={handleDownloadCSV}
              >
                <ArrowDownward />
                <span>CSV形式でエクスポート</span>
              </button>
            </div>

            {isOpenDatePicker && (
              <div
                className={css({
                  position: "absolute",
                  top: "30px",
                  right: "0",
                  left: 0,
                  bg: "white",
                  zIndex: "10",
                  borderRadius: "8px",
                  border: "1px solid",
                  borderColor: "border.secondary",
                  shadow: "md",
                  p: "12px",
                  width: "fit-content",
                })}
              >
                <DateTimeRangePicker
                  limit={limit}
                  offset={offset}
                  onSubmit={handleDateSubmit}
                  onCancel={() => setIsOpenDatePicker(false)}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={css({
            // DateRangePickerの高さを考慮して、最低限の高さを確保
            minHeight: "460px",
          })}
        >
          <div
            className={css({
              mt: "12px",
              bg: "white",
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid",
              borderColor: "border.secondary",
              fontSize: "12px",
              minWidth: "800px",
              mb: "40px",
            })}
          >
            <table
              className={css({
                width: "100%",
                borderCollapse: "collapse",
              })}
            >
              <thead>
                <tr
                  className={css({
                    borderBottom: "1px solid",
                    borderColor: "border.secondary",
                    bg: "white",
                  })}
                >
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    取引ID
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    日時
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    店舗名
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    支払ユーザーID
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    性別
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    年齢
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "right",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    ポイント
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    QRコードタグ
                  </th>
                  <th
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "center",
                      fontWeight: "normal",
                      color: "text.secondary",
                    })}
                  >
                    操作
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={9}
                      className={css({
                        py: "40px",
                        textAlign: "center",
                        color: "text.secondary",
                      })}
                    >
                      読み込み中...
                    </td>
                  </tr>
                ) : transactions.length === 0 ? (
                  <tr>
                    <td
                      colSpan={9}
                      className={css({
                        py: "40px",
                        textAlign: "center",
                        color: "text.secondary",
                      })}
                    >
                      取引履歴がありません
                    </td>
                  </tr>
                ) : (
                  transactions.map((group) => (
                    <Row
                      key={group.id}
                      group={group}
                      onRefund={handleClickRefund}
                    />
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
