import {
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMonths,
} from "date-fns";
import { useCallback, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ja } from "date-fns/locale";
import { DateRangePicker, type RangeKeyDict } from "react-date-range";
import { css } from "../../styled-system/css";

export type DateTimeRangePickerProps = {
  limit: Date;
  offset: Date;
  onSubmit: (range: { limit: Date; offset: Date }) => void;
  onCancel: () => void;
};

export function DateTimeRangePicker({
  limit,
  offset,
  onSubmit,
  onCancel,
}: DateTimeRangePickerProps) {
  const [dateRange, setDateRange] = useState({
    limit,
    offset,
    key: "selection",
  });

  const handleSelect = useCallback(
    (ranges: RangeKeyDict) => {
      if (ranges.selection.startDate && ranges.selection.endDate) {
        let { endDate } = ranges.selection;
        const { startDate } = ranges.selection;

        // 日付クリックで選択した場合、endDateを全日付にする
        // デフォルトでは00:00:00になっているため、endOfDayで上書きをする
        if (
          endDate.getHours() === 0 &&
          endDate.getMinutes() === 0 &&
          endDate.getSeconds() === 0
        ) {
          endDate = endOfDay(endDate);
        }

        // startDateの時間を直接編集するとendDateがstartDateと同じになってしまう
        // そのため、startDateとendDateが同じ場合は直前のoffsetで上書きする
        if (startDate === endDate) {
          endDate = dateRange.offset;
        }

        setDateRange({
          limit: ranges.selection.startDate,
          offset: endDate,
          key: "selection",
        });
      }
    },
    [dateRange],
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      limit: dateRange.limit,
      offset: dateRange.offset,
    });
  }, [dateRange, onSubmit]);

  return (
    <>
      <div
        className={css({
          border: "1px solid",
          borderColor: "border.secondary",
          borderRadius: "8px",
        })}
      >
        <DateRangePicker
          locale={ja}
          ranges={[
            {
              startDate: dateRange.limit,
              endDate: dateRange.offset,
              key: "selection",
            },
          ]}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          inputRanges={[]}
          dateDisplayFormat="yyyy/MM/dd HH:mm"
          editableDateInputs={true}
          staticRanges={[
            {
              label: "過去1時間",
              range: () => ({
                startDate: subHours(new Date(), 1),
                endDate: endOfDay(new Date()),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "過去3時間",
              range: () => ({
                startDate: subHours(new Date(), 3),
                endDate: endOfDay(new Date()),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "今日",
              range: () => ({
                startDate: startOfDay(new Date()),
                endDate: endOfDay(new Date()),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "昨日",
              range: () => ({
                startDate: startOfDay(subDays(new Date(), 1)),
                endDate: endOfDay(subDays(new Date(), 1)),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "一昨日",
              range: () => ({
                startDate: startOfDay(subDays(new Date(), 2)),
                endDate: endOfDay(subDays(new Date(), 2)),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "過去3日",
              range: () => ({
                startDate: startOfDay(subDays(new Date(), 2)),
                endDate: endOfDay(new Date()),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "今週",
              range: () => ({
                startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
                endDate: endOfDay(new Date()),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "今月",
              range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: endOfDay(new Date()),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "先月",
              range: () => ({
                startDate: startOfMonth(subMonths(new Date(), 1)),
                endDate: endOfMonth(subMonths(new Date(), 1)),
                key: "selection",
              }),
              isSelected: () => false,
            },
          ]}
        />
      </div>
      <div
        className={css({
          display: "flex",
          gap: "8px",
          p: "8px",
        })}
      >
        <button
          type="button"
          className={css({
            border: "1px solid",
            borderColor: "border.secondary",
            borderRadius: "8px",
            px: "12px",
            py: "4px",
            cursor: "pointer",
            ml: "auto",
          })}
          onClick={onCancel}
        >
          キャンセル
        </button>
        <button
          type="button"
          className={css({
            bg: "surface.accentPrimary",
            color: "text.inverse",
            border: "none",
            borderRadius: "8px",
            px: "30px",
            py: "4px",
            cursor: "pointer",
          })}
          onClick={handleSubmit}
        >
          確定
        </button>
      </div>
    </>
  );
}
