import { Timestamp } from "@bufbuild/protobuf";
import { createRouterTransport } from "@connectrpc/connect";
import { startOfMonth } from "date-fns";
import { AccountService } from "schema/gen/es/chiikipoint/companyconsole/v2/account_service_connect";
import {
  type GetTenantRequest,
  GetTenantResponse,
  type LoginRequest,
  LoginResponse,
} from "schema/gen/es/chiikipoint/companyconsole/v2/account_service_pb";
import { CompanyService } from "schema/gen/es/chiikipoint/companyconsole/v2/service_connect";
import {
  type GetMyCompanyRequest,
  GetMyCompanyResponse,
  type GetRedemptionsRequest,
  GetRedemptionsResponse,
  type GetTransactionsRequest,
  GetTransactionsResponse,
  type RefundRequest,
  RefundResponse,
  type UpdateMyCompanyImageRequest,
  UpdateMyCompanyImageResponse,
} from "schema/gen/es/chiikipoint/companyconsole/v2/service_pb";
import {
  Tenant,
  TimePaginationResponse,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import {
  CompanyWithStores,
  TransactionGroup,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";

export const mockGetTenant = async (
  _req: GetTenantRequest,
): Promise<GetTenantResponse> => {
  const tenant = new Tenant({
    id: "550e8400-e29b-41d4-a716-446655440000",
    name: "デモ地域ポイント",
    slug: "demo",
    serviceId: "b43596db-9481-432f-ae7f-d4a89de9af7c",
    contact: "0120-1234-5678",
    iconUrl: "/misc/icons/demo.png",
  });
  return new GetTenantResponse({
    tenant,
  });
};

export const mockLogin = async (_req: LoginRequest): Promise<LoginResponse> => {
  return new LoginResponse({
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NDIyOTc2MDAsInN1YiI6Im1vY2stdXNlciIsImlhdCI6MTcxMDI5NzYwMH0.7h8SZgXBbg_kT5_JncQPZvHqGnwqI1n6Q6BxJh4r7UY",
  });
};

export const mockGetMyCompany = async (
  _req: GetMyCompanyRequest,
): Promise<GetMyCompanyResponse> => {
  const companyWithStores = new CompanyWithStores({
    company: {
      id: "67e55044-10b1-426f-9247-bb680e5fe0c8",
      name: "みやポマート",
      ownerName: "日本橋 太郎",
      address: "宮城県仙台市青葉区一番町１丁目３−２",
      contact: "022-123-4567",
      iconUrl: "/misc/default-store-icon.png",
      bankName: "日本橋銀行",
      bankBranchName: "日本橋支店",
      bankAccountType: "普通",
      bankAccountNumber: "1234567",
      bankAccountHolderName: "日本橋 太郎",
    },
    stores: [
      {
        id: "RYks43cB",
        name: "みやぎ生協",
        fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
        city: "宮城県仙台市青葉区",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
      {
        id: "DJuKFAPf",
        name: "丸森観光物産",
        fullAddress: "宮城県伊具郡丸森町字下滝12",
        city: "宮城県伊具郡丸森町",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
      {
        id: "LaX36SYr",
        name: "テスト店舗3",
        fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
        city: "宮城県仙台市青葉区",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
      {
        id: "Mm2WXq8P",
        name: "テスト店舗4とても長い店舗名のテストtesting",
        fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
        city: "宮城県仙台市青葉区",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
    ],
  });
  return new GetMyCompanyResponse({
    companyWithStores,
  });
};

export const mockUpdateMyCompanyImage = async (
  _req: UpdateMyCompanyImageRequest,
): Promise<UpdateMyCompanyImageResponse> => {
  return new UpdateMyCompanyImageResponse();
};

export const mockGetTransactions = async (
  _req: GetTransactionsRequest,
): Promise<GetTransactionsResponse> => {
  // return new GetTransactionsResponse({
  //   transactionGroups: [],
  // });
  const transactionGroups: TransactionGroup[] = [1, 2, 3].map(
    (i) =>
      new TransactionGroup({
        id: `2b0d7b3dcb6${i}`,
        transactionDetails: [
          {
            transaction: {
              id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
              groupId: "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
              userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
              type: 2, // PAYMENT
              balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
              amount: BigInt(-100),
              fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
              reason: "支払い",
              createdAt: {
                seconds: BigInt(1739634210),
                nanos: 0,
              },
              qrId: "テストQR",
            },
            store: {
              id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
              name: "みやポマート",
              fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
              city: "宮城県仙台市青葉区",
              location: {
                latitude: 38.25783587179756,
                longitude: 140.87284595831724,
              },
              category: "コンビニ",
              iconUrl: "/misc/storefront.png",
            },
            user: {
              gender: 1,
              ageGroup: BigInt(20),
            },
          },
        ],
      }),
  );
  const transactionGroupsWithRedemption = [
    ...transactionGroups,
    new TransactionGroup({
      id: "03ef2817e92d",
      transactionDetails: [
        {
          transaction: {
            id: "62fca4da-36a5-4d14-9be2-df371067129f",
            groupId: "0f64f87e-24d5-4fc2-a0cd-067f51dc5efc",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 2, // PAYMENT
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(-100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "支払い",
            qrId: "返金",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
          user: {
            ageGroup: BigInt(40),
            gender: 2,
          },
        },
        {
          transaction: {
            id: "06e4fd98-82d1-457d-b3bd-ccb62f83445c",
            groupId: "0f64f87e-24d5-4fc2-a0cd-067f51dc5efc",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 3, // Refund
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "返金",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
        },
      ],
    }),
    new TransactionGroup({
      id: "03ef2817e92d",
      transactionDetails: [
        {
          transaction: {
            id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
            groupId: "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 2, // PAYMENT
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(-100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "支払い",
            qrId: "精算",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
          user: {
            ageGroup: BigInt(30),
            gender: 2,
          },
        },
        {
          transaction: {
            id: "1269da8b-8ff6-4a30-b36d-98210bd4f865",
            groupId: "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 6, // REDEEM
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "精算",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
        },
      ],
    }),
    new TransactionGroup({
      id: "03ef2817e92d",
      transactionDetails: [
        {
          transaction: {
            id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
            groupId: "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 2, // PAYMENT
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(-100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "支払い",
            qrId: "返金&精算",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
          user: {
            ageGroup: BigInt(50),
            gender: 1,
          },
        },
        {
          transaction: {
            id: "774e64bb-614e-4f7e-b8d3-06c36bf7705a",
            groupId: "8c66f52a-2b76-4776-95b8-e7eb78f2c819",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 3, // Refund
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "返金",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
        },
        {
          transaction: {
            id: "2adb3cc9-13f4-48f3-9eaa-f87ecdc56e19",
            groupId: "8c66f52a-2b76-4776-95b8-e7eb78f2c819",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 6, // REDEEM
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(0),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "精算",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
        },
      ],
    }),
    new TransactionGroup({
      id: "3e7f8640d1de",
      transactionDetails: [
        {
          transaction: {
            id: "62c56c72-6b2d-4d58-b2ec-a6f963f46d87",
            groupId: "e0846d12-8700-49c0-ad53-3460d59d37af",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 2, // PAYMENT
            balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
            amount: BigInt(-100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "支払い",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
            qrId: "複数ソース",
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
          user: {
            ageGroup: BigInt(70),
            gender: 2,
          },
        },
        {
          transaction: {
            id: "ce287ced-e3ca-4342-b26a-687109e83fa9",
            groupId: "e0846d12-8700-49c0-ad53-3460d59d37af",
            userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
            type: 2, // PAYMENT
            balanceAddId: "ed1475ec-87e2-4952-9571-18589441df26",
            amount: BigInt(-100),
            fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
            reason: "支払い",
            createdAt: {
              seconds: BigInt(1739634210),
              nanos: 0,
            },
            qrId: "複数ソース",
          },
          store: {
            id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/storefront.png",
          },
        },
      ],
    }),
  ];
  return new GetTransactionsResponse({
    transactionGroups: transactionGroupsWithRedemption,
    pagination: new TimePaginationResponse({
      nextOffset: Timestamp.fromDate(
        new Date(Date.now() + 1000 * 60 * 60 * 24),
      ),
    }),
  });
};

export const mockGetRedemptions = async (
  req: GetRedemptionsRequest,
): Promise<GetRedemptionsResponse> => {
  const offset = req.pagination?.limit?.toDate();
  const currentMonthStart = startOfMonth(new Date());
  const isCurrentMonth =
    offset &&
    Math.floor(offset.getTime() / 1000) ===
      Math.floor(currentMonthStart.getTime() / 1000);

  const emptyResponse = new GetRedemptionsResponse({
    transactionGroups: [],
    pagination: new TimePaginationResponse({
      nextOffset: Timestamp.fromDate(
        new Date(Date.now() + 1000 * 60 * 60 * 24),
      ),
    }),
  });
  if (!isCurrentMonth) {
    return emptyResponse;
  }

  // mockGetMyCompanyで取得できる店舗情報を利用する
  const myCompanyResponse = await mockGetMyCompany({} as GetMyCompanyRequest);
  const companyStores = myCompanyResponse.companyWithStores?.stores ?? [];

  // すべてのトランザクションで利用するタイムスタンプ
  const now = Timestamp.fromDate(offset);

  // 各ケースを別々の TransactionGroup として定義
  const transactionGroups: TransactionGroup[] = [
    // ケース 1: 単一の支払いと精算（例: 支払いが -150、精算が +150）
    new TransactionGroup({
      id: "group1",
      transactionDetails: [
        {
          transaction: {
            id: "tx-group1-payment",
            groupId: "group1",
            userId: "user1",
            type: 2, // PAYMENT
            balanceAddId: "add1",
            amount: BigInt(-150),
            fundSourceId: "fund1",
            reason: "支払い",
            createdAt: now,
          },
          store: companyStores[0],
          user: { ageGroup: BigInt(20), gender: 1 },
        },
        {
          transaction: {
            id: "tx-group1-redeem",
            groupId: "group1",
            userId: "user1",
            type: 6, // REDEEM
            balanceAddId: "add1",
            amount: BigInt(150),
            fundSourceId: "fund1",
            reason: "精算",
            createdAt: now,
            redemptionId: "redemption-group1",
          },
          store: companyStores[0],
        },
      ],
    }),
    // ケース 2: 複数の支払いがある場合（例: 支払いが -120 と -80、合計 -200 の精算 +200）
    new TransactionGroup({
      id: "group2",
      transactionDetails: [
        {
          transaction: {
            id: "tx-group2-payment1",
            groupId: "group2",
            userId: "user2",
            type: 2, // PAYMENT
            balanceAddId: "add2",
            amount: BigInt(-120),
            fundSourceId: "fund2",
            reason: "支払い",
            createdAt: now,
          },
          store: companyStores[1],
          user: { ageGroup: BigInt(30), gender: 2 },
        },
        {
          transaction: {
            id: "tx-group2-payment2",
            groupId: "group2",
            userId: "user2",
            type: 2, // PAYMENT
            balanceAddId: "add2",
            amount: BigInt(-80),
            fundSourceId: "fund2",
            reason: "追加支払い",
            createdAt: now,
          },
          store: companyStores[1],
        },
        {
          transaction: {
            id: "tx-group2-redeem",
            groupId: "group2",
            userId: "user2",
            type: 6, // REDEEM
            balanceAddId: "add2",
            amount: BigInt(200),
            fundSourceId: "fund2",
            reason: "精算",
            createdAt: now,
            redemptionId: "redemption-group1",
          },
          store: companyStores[1],
        },
      ],
    }),
    // ケース 3: 支払いに返金が含まれる場合（例: 支払いが -50, -50、返金 +100、精算は 0 円）
    new TransactionGroup({
      id: "group3",
      transactionDetails: [
        {
          transaction: {
            id: "tx-group3-payment1",
            groupId: "group3",
            userId: "user3",
            type: 2, // PAYMENT
            balanceAddId: "add3",
            amount: BigInt(-50),
            fundSourceId: "fund3",
            reason: "支払い",
            createdAt: now,
          },
          store: companyStores[2],
          user: { ageGroup: BigInt(25), gender: 2 },
        },
        {
          transaction: {
            id: "tx-group3-payment2",
            groupId: "group3",
            userId: "user3",
            type: 2, // PAYMENT
            balanceAddId: "add3",
            amount: BigInt(-50),
            fundSourceId: "fund3",
            reason: "追加支払い",
            createdAt: now,
          },
          store: companyStores[2],
        },
        {
          transaction: {
            id: "tx-group3-refund",
            groupId: "group3",
            userId: "user3",
            type: 3, // REFUND
            balanceAddId: "add3",
            amount: BigInt(100),
            fundSourceId: "fund3",
            reason: "返金",
            createdAt: now,
          },
          store: companyStores[2],
          user: { ageGroup: BigInt(25), gender: 2 },
        },
        {
          transaction: {
            id: "tx-group3-redeem",
            groupId: "group3",
            userId: "user3",
            type: 6, // REDEEM
            balanceAddId: "add3",
            amount: BigInt(0), // 返金がある場合は常に 0 円
            fundSourceId: "fund3",
            reason: "精算",
            createdAt: now,
            redemptionId: "redemption-group1",
          },
          store: companyStores[2],
        },
      ],
    }),
    // ケース 4: 複数回の支払いがある場合（例: 支払いが -70, -80, -50 の合計 -200、精算が +200）
    new TransactionGroup({
      id: "group4",
      transactionDetails: [
        {
          transaction: {
            id: "tx-group4-payment1",
            groupId: "group4",
            userId: "user4",
            type: 2, // PAYMENT
            balanceAddId: "add4",
            amount: BigInt(-70),
            fundSourceId: "fund4",
            reason: "支払い",
            createdAt: now,
          },
          store: companyStores[3],
          user: { ageGroup: BigInt(40), gender: 1 },
        },
        {
          transaction: {
            id: "tx-group4-payment2",
            groupId: "group4",
            userId: "user4",
            type: 2, // PAYMENT
            balanceAddId: "add4",
            amount: BigInt(-80),
            fundSourceId: "fund4",
            reason: "追加支払い",
            createdAt: now,
          },
          store: companyStores[3],
        },
        {
          transaction: {
            id: "tx-group4-payment3",
            groupId: "group4",
            userId: "user4",
            type: 2, // PAYMENT
            balanceAddId: "add4",
            amount: BigInt(-50),
            fundSourceId: "fund4",
            reason: "更に追加支払い",
            createdAt: now,
          },
          store: companyStores[3],
        },
        {
          transaction: {
            id: "tx-group4-redeem",
            groupId: "group4",
            userId: "user4",
            type: 6, // REDEEM
            balanceAddId: "add4",
            amount: BigInt(200),
            fundSourceId: "fund4",
            reason: "精算",
            createdAt: now,
            redemptionId: "redemption-group2",
          },
          store: companyStores[3],
        },
      ],
    }),
  ];

  if (req.storeId) {
    const filteredGroups = transactionGroups
      .map((group) => {
        const filteredDetails = group.transactionDetails.filter(
          (td) => td.store?.id === req.storeId,
        );
        if (filteredDetails.length > 0) {
          return new TransactionGroup({
            ...group,
            transactionDetails: filteredDetails,
          });
        }
        return null;
      })
      .filter((group) => group !== null) as TransactionGroup[];

    return new GetRedemptionsResponse({
      transactionGroups: filteredGroups,
      pagination: new TimePaginationResponse({
        nextOffset: Timestamp.fromDate(
          new Date(Date.now() + 1000 * 60 * 60 * 24),
        ),
      }),
    });
  }

  return new GetRedemptionsResponse({
    transactionGroups,
    pagination: new TimePaginationResponse({
      nextOffset: Timestamp.fromDate(
        new Date(Date.now() + 1000 * 60 * 60 * 24),
      ),
    }),
  });
};

export const mockRefund = async (
  _req: RefundRequest,
): Promise<RefundResponse> => {
  const transactionGroup = new TransactionGroup({
    id: "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
    transactionDetails: [],
  });
  return new RefundResponse({
    transactionGroup,
  });
};

export const accountServiceMockTransport = createRouterTransport(
  ({ service }) => {
    service(AccountService, {
      getTenant: mockGetTenant,
      login: mockLogin,
    });
  },
);

export const companyServiceMockTransport = createRouterTransport(
  ({ service }) => {
    service(CompanyService, {
      getMyCompany: mockGetMyCompany,
      updateMyCompanyImage: mockUpdateMyCompanyImage,
      getTransactions: mockGetTransactions,
      getRedemptions: mockGetRedemptions,
      refund: mockRefund,
    });
  },
);
